<template>
    <div>
        <div class="login_all">
            <vue-particles
                color="#fff"
                :particleOpacity="0.7"
                :particlesNumber="50"
                shapeType="circle"
                :particleSize="10"
                linesColor="#fff"
                :linesWidth="1"
                :lineLinked="true"
                :lineOpacity="0.4"
                :linesDistance="150"
                :moveSpeed="2"
                :hoverEffect="true"
                hoverMode="grab"
                :clickEffect="true"
                clickMode="push"
                class="lizi"
            >
            </vue-particles>
            
            <img src="./../../assets/img/logo.png" alt="" style="width:auto; height:60px; margin:40px 80px;">
        </div>
        <div class="logindialog">
            <ul class="logind_ul clearfix">
                <!-- <li :class="li_login==1?'li_hlw':''" @click="cho_li(1)">
                    <span>
                        "互联网+"平台
                    </span>
                </li> -->
                <li :class="li_login==2?'li_hlw':''" @click="cho_li(2)">
                    <span>
                        "创赛通"平台
                    </span>
                </li>
            </ul>
            <hlwLogin v-show="li_login==1" :Exount="Exount" @closepop="closepop"></hlwLogin>
            <dacLogin v-show="li_login==2" :Exount="Exount" @closepop="closepop"></dacLogin>
        </div>
    </div>
</template>
<script>
import hlw_login from './../../components/hlw_login'
import dac_login from './../../components/dac_login'
export default {
    components:{
        hlwLogin:hlw_login,
        dacLogin:dac_login,
    },
    data () {
        return {
            li_login:2,
            loginForm:{
                radio:1,
            },
            Form:{},
            Exount:false,
            rules:{},
            istrue:1,
            msg:'',
            Vercode:'获取验证码',
            disabled:false,
        }
    },
    methods: {
        closepop(data){
            this.Exount = data
        },
        // 登录平台切换
        cho_li(val){
            this.li_login = val
            this.Exount = false
        },
        registered(val){
            this.Exount = val
        },
    },
    mounted() {
        
    },
}
</script>
<style scoped>
@import './../../assets/css/login/login.css';
</style>
<style >
.aaa{
    margin-bottom: 5px;
    text-align: center;
}
.login_button .el-button{
    width: 100%;
}
.login_div .el-input-group__append{
    padding: 0;
    width: 123px;
    text-align: center;
}
</style>